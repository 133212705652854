@use "../config" as *;
@forward "common-fixtures";
.waf-scorestrip:not(.waf-takeover-scorestrip) {
  &.waf-component {
    @extend %p-0;
  }
  .card {
    &-meta-title {
      font-size: 1.4rem;
      @extend %mb-3;
      @extend %accent-light;
    }
    &-list {
      &-item {
        @extend %mb-5;
      }
      &-wrapper {
        padding: var(--space-3) var(--space-2) var(--space-1);
        flex-direction: column;
        box-sizing: border-box;
        @extend %p-3-2;
        @extend %quarter-radius;
        @extend %white-bg;
      }
    }
    &-meta {
      @extend %text-center;
    }
    &-item-wrapper {
      &:not(:last-child) {
        @include separator(90%, x, 0.1rem, secondary, 1);
        @extend %relative;
        &::after {
          bottom: var(--space-2-neg);
        }
        &.live {
          &::after {
            @extend %d-none;
          }
        }
      }
      &.live {
        .card {
          &-action {
            border-color: hsl(var(--hsl-white)/0.1);
            .btn-site,
            .card-client {
              border-color: hsl(var(--hsl-white)/0.1);
              &::before {
                @extend %white-2;
              }
              .text {
                @extend %white-8;
              }
            }
          }
          &-client {
            border: .2rem solid hsl(var(--hsl-white)/0.5);
            background-image: url('/static-assets/images/data-partners/jio-cinema-reverse.png?v=#{$image-version}');
            background-size: 6.6rem;
            background-repeat: no-repeat;
            background-position: center;
            .image {
              visibility: hidden;
            }
          }
        }
      }
    }
    &-body {
      margin-top: var(--space-2);
      height: 4.7rem;
      align-content: flex-end;
    }
    &-action {
      order: -1;
      border-top: .1rem solid hsl(var(--hsl-secondary-light) / .1);
      flex-direction: row-reverse;
      gap: var(--space-2);
      @extend %pt-2;
      @extend %flex-c-c;
      .btn-site,
      .card-client {
        height: 2rem;
        border: .1rem solid var(--secondary-light);
        font-weight: 500;
        gap: var(--space-1);
        @extend %transparent-bg;
        @extend %p-1-2;
        @extend %quarter-radius;
        @extend %flex-n-c;
        &:not(.btn-matchcenter) {
          @extend %relative;
          z-index: var(--z-fixtures-page-btn);
        }
        &::before {
          font-weight: normal;
        }
        .text {
          width: max-content;
          @extend %font-8;
        }
      }
      .btn-matchcenter {
        height: unset;
        @include border(0);
        &::before {
          top: 31%;
        }
      }
      .btn-preview,
      .btn-report {
        border-color: var(--secondary-dark);
        .text {
          @extend %secondary-dark;
        }
        &::before {
          @extend %secondary-dark;
        }
      }
      .btn-highlight {
        border-color: var(--primary);
        @extend %primary;
        .text {
          @extend %primary;
        }
        &::before {
          width: 1.2rem;
          height: 1.2rem;
          @include icon(play, 6);
          @extend %primary;
          @extend %circle-radius;
          @extend %flex-c-c;
        }
      }
      .btn-ticket {
        border-color: var(--primary);
        @extend %primary;
        &::before {
          @include icon(ticket, 10);
        }
      }
      .btn-preview {
        &::before {
          @include icon(preview, 10);
        }
      }
      .btn-highlight {
        &::before {
          @include icon(play, 10);
        }
      }
      .card-client {
        width: 9.4rem;
        .link {
          @extend %d-block;
        }
        .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding: 0.5rem;
        }
      }
    }
  }
  .venue {
    font-size: 1rem;
    &::before {
      @include icon(stadium);
      @extend %mr-2;
    }
  }
  .date,
  .time,
  .match,
  .full,
  .team-client,
  .btn-ticket,
  .card-number {
    @extend %d-none;
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-scorestrip:not(.waf-takeover-scorestrip) {
    &.waf-component {
      padding: var(--space-4) 0 0;
    }
    .card {
      &-list-item {
        &:not(:first-child) {
          display: block;
        }
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: var(--space-5);
        }
      }
      &-list-wrapper {
        padding: var(--space-3) var(--space-2);
      }
      &-item-wrapper {
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .head-tab {
      flex-shrink: 0;
      .active {
        a {
          color: var(--white);
        }
      }
    }
    .score-list-section {
      height: calc(var(--window-inner-height) - 25rem);
      overflow-y: auto;
      @include custom-scroll();
    }
  }
}