@use './config/' as *;
@forward "./main-scss/";
@forward "./listing/home-listing";
@forward "./listing/listing";
@forward "./listing/common-listing";
@forward "./showcase/common-showcase";
@forward "./showcase/home-showcase";
@forward "./standings/common-standings";
@forward "./standings/home-standings";
@forward "./fixtures/home-fixtures";
@forward "./fixtures/takeover-scorestrip";
@forward "./tracker/tracker";
@forward "./modules/ads";
@forward "./stats/home-stats";
@forward "./stats/home-player-stats";
@forward "./stats/home-awards";
.load-bg-pattern {
    // html {
    @each $key in (video, photo, news) {
        .waf-row-home-#{$key} {
            background-color: var(--secondary-dark);
            @include bgImg("cssimages/home-page/mobile/hp-#{$key}-bg.svg");
        }
      
    }
    .waf-row-home-club-stats:after{
       @include bgImg("svg/club-pattern.svg");
    }
    .waf-row-home-tracker{
        // @include background("cssimages/home-page/mobile/tracker-bg.jpg",top/cover no-repeat,linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%));
        background: url("/static-assets/images/cssimages/home-page/mobile/tracker-bg.jpg?v=#{$image-version}") top/cover no-repeat,linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%);
    }
    .waf-showcase:where(:not(.trending-showcase)) {
        .dependancy-wrapper .accordion-body::before {
            @include bgImg("/svg/hexagon.svg");
        }
    }
    .waf-standings::before {
        @include bgImg("/svg/hexagon.svg");
    }
    .waf-row-tracker-row::before,
    .waf-row-tracker-row::after {
        @include bgImg('svg/hexagon.svg');
    }
    .waf-app-banner {
        &::after {
            background: url("/static-assets/images/cssimages/home-page/hexagon-pattern-bg.svg?v=#{$image-version}") center / contain no-repeat;
        }
        .app-banner-wrap {
            background: url("/static-assets/images/banner/app-download-banner-mob.png?v=#{$image-version}");
            background-size: cover;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .load-bg-pattern {
        // html {
        @each $key in (video, photo, news) {
            .waf-row-home-#{$key} {
                background-color: var(--secondary-dark);
                @include bgImg("cssimages/home-page/hp-#{$key}-bg.svg");
            }
        }
        .waf-row-poll-bg {
            &::after {
                @include bgImg("cssimages/home-page/hexagon-pattern-bg.svg");
            }
        }
        .waf-app-banner {
            .app-banner-wrap {
                background-image: url("/static-assets/images/banner/app-download-banner-web.png?v=#{$image-version}");
            }
        }
    }
}