@use "../config" as *;
.waf-standings {
    @extend %relative;
    .waf-footer {
        @extend %d-none;
    }
    .filter-container {
        @extend %d-none;
    }
    .waf-body {
        border-radius: var(--quarter-radius);
        padding-bottom: 6.8rem;
    }
}
.table-responsive {
    @extend %relative;
}
.standings-table {
    @include custom-scroll();
    .table {
        &-head {
            z-index: var(--z-home-standing);
            .team,
            .points {
                .text {
                    @extend %capitalize;
                }
            }
        }
        &-body {
            @extend %pt-8;
        }
        &-row-wrap {
            @extend %relative;
            @extend %w-100;
            &::after {
                content: '';
                height: 0.1rem;
                @extend %w-100;
                @extend %position-b-l;
            }
        }
    }
    .position {
        @extend %w-20;
    }
    .team {
        justify-content: flex-start;
        @extend %w-80;
        .text {
            @extend %uppercase;
        }
        &-image {
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            padding: .3rem;
            @extend %white-bg;
            @extend %circle-radius;
            @extend %hidden;
        }
        &-information {
            @extend %flex-n-c;
            @extend %w-100;
        }
        &-anchor {
            @extend %white;
            .text:hover,
            .name:hover {
                text-decoration: underline;
            }
        }
        &-name {
            @extend %pl-4;
            .text {
                font-size: 1.2rem;
                font-weight: 700;
                @extend %text-left;
            }
        }
    }
    .row-head {
        @extend %flex;
        @extend %w-100;
    }
}
.table-left {
    width: 60%;
    flex-shrink: 0;
    .table {
        &-head {
            border-radius: var(--quarter-radius) 0 0 0;
            width: 60%;
            @extend %position-t-l;
            @extend %pl-2;
        }
        &-body {
            border-radius: 0 0 var(--quarter-radius) 0;
            @extend %pl-2;
        }
        &-row {
            @extend %relative;
            &.highlight {
                &::after {
                    content: "";
                    width: 100%;
                    border-radius: var(--quarter-radius) 0 0 var(--quarter-radius);
                    @extend %h-100;
                    @extend %position-t-l;
                }
            }
        }
    }
}
.table-right {
    width: 40%;
    flex-shrink: 0;
    .table {
        &-head {
            border-radius: 0 var(--quarter-radius) 0 0;
            width: 40%;
            @extend %position-t-r;
            @extend %pr-2;
        }
        &-body {
            border-radius: 0 0 var(--quarter-radius) 0;
            @extend %pr-2;
        }
        &-data {
            width: 33.33%;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-standings {
        .waf-body {
            padding-bottom: 0;
        }
    }
    .table {
        &-row {
            &.highlight {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}