@use "../config" as *;
.waf-row-home-video,
.waf-row-home-photo,
.waf-row-home-news {
    background-repeat: no-repeat;
    background-size: cover;
    .waf-listing {
        .first-list {
            @extend %mx-2-neg;
        }
    }
}
.waf-row-home-video {
    .waf-listing {
        .first-list {
            margin-bottom: 7rem;
            @extend %remove-common-gradient;
            .article-content {
                pointer-events: unset;
            }
        }
        .waf-head {
            @extend %mt-3;
        }
    }
}
@media only screen and (min-width: $small-mobile-min-width) and (max-width: $mobile-max-width) {
    .waf-row-home-video {
        height: var(--showcase-video-space);
        width: var(--window-inner-width);
        margin-top: var(--window-inner-width);
        z-index: var(--z-listing);
    }
}
.waf-row-home-news {
    .waf-listing {
        .first-list {
            @extend %mb-3;
            @extend %relative;
            @include card-count(1, var(--space-0));
            &::after {
                content: "";
                height: 0.1rem;
                width: calc(100% - var(--space-6));
                @extend %secondary-light-bg-2;
                @include position(null, 1.5rem, 0, null);
            }
        }
        .second-list {
            @include card-count(1, var(--space-2), wrap);
            .article-content {
                .item-type-icon {
                    @extend %white;
                }
            }
            @extend %rhs-card-list;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-home-video,
    .waf-row-home-photo,
    .waf-row-home-news {
        .waf-listing {
            .first-list {
                margin-inline: 0;
            }
        }
    }
    .waf-row-home-video {
        .waf-head {
            z-index: var(--z-listing-head);
        }
        .waf-listing {
            .first-list {
                position: relative;
                margin-bottom: var(--space-3);
                @include flex-config(flex, row, flex-end, center);
                @include card-count(1, var(--space-0));
                .article-wrap {
                    position: static;
                    gap: var(--space-3);
                    @include flex-config(flex, row-reverse, flex-end, center);
                }
                .article-content {
                    position: static;
                    width: 33.33%;
                    padding-inline: 0;
                }
                .article-thumbnail {
                    width: 66.67%;
                    .img-box {
                        &::after {
                            content: unset;
                        }
                    }
                }
                .article-title {
                    @include truncate(2, 28, 36, $font-primary);
                }
            }
            .waf-head {
                margin-bottom: 0;
                transform: translateY(4rem);
                position: relative;
                z-index: var(--z-listing-head);
            }
        }
    }
    .waf-row-home-news {
        .waf-listing {
            .article-list {
                @include card-count(4, var(--space-3));
            }
            .first-list {
                margin-bottom: 0;
                &::after {
                    content: unset;
                }
            }
        }
    }
}