@use "../config/" as *;
.waf-home-awards {
  --_logo-width: 3.8rem;
  --_team-card-height: 16.2rem;
  --_boot-width: 5.2rem;
  --_boot-ar: .84;
  --_glove-width: 3.8rem;
  --_glove-ar: .55;
  --_stats-label-height: 3.4rem;
  padding: var(--space-8) 0 var(--space-13) var(--space-3);
  margin: 0 var(--space-2-neg);
  background: var(--secondary-dark) url("/static-assets/images/cssimages/home-page/mobile/awards-bg.png?#{$image-version}") center / cover no-repeat;
  contain: paint;
  isolation: isolate;
  @extend %relative;
  &::before {
    content: "";
    width: 67.5%;
    aspect-ratio: .87;
    z-index: map-get($map: $zindex, $key: negative);
    background: url('/static-assets/images/svg/hexagon.svg?v=#{$image-version}') center / contain no-repeat;
    filter: invert(100%) sepia(6%) saturate(177%) hue-rotate(275deg) brightness(118%) contrast(100%);
    pointer-events: none;
    opacity: .05;
    @include position(-5%, null, null, -6.3%);
  }
  .waf-head {
    @extend %mb-3;
    .title {
      @extend %font-18-pb;
    }
  }
  .title {
    line-height: 1;
    @extend %mb-0;
    @extend %white;
    @extend %uppercase;
  }
  .head-tab {
    z-index: map-get($map: $zindex, $key: default);
    @include position(null, var(--space-3), var(--space-6));
    a {
      height: 2.4rem;
      padding: 0;
      @extend %flex-n-c;
      @extend %white;
      @extend %font-12-pm;
      @extend %gap-1;
      @extend %capitalize;
      &::after {
        width: 1.8rem;
        aspect-ratio: 1;
        border-radius: .3rem;
        @include icon(chevron-right, 10, 10);
        @extend %flex-c-c;
        @extend %primary-bg;
      }
    }
  }
  .stats-label {
    width: 53.33%;
    min-width: 18.4rem;
    height: 3.4rem;
    background: linear-gradient(90.17deg, var(--accent-400) 2.51%, var(--accent-200) 31.87%, var(--accent-100) 55.6%, var(--accent-300) 79.55%, var(--accent-400) 103.44%),
      linear-gradient(89.77deg, hsl(var(--hsl-white) / 0) 6.19%, hsl(var(--hsl-white) / .3) 44.57%, hsl(var(--hsl-white) / 0) 82.54%);
    @include position(null, null, var(--_team-card-height), 31%);
    @extend %pl-5;
    @extend %flex-n-c;
    @extend %rounded-radius;
    &::after {
      content: "";
      width: var(--_boot-width);
      aspect-ratio: var(--_boot-ar);
      background: url('/static-assets/images/cssimages/boot.png?v=#{$image-version}') center / contain no-repeat;
      transform: rotate(-2.35deg);
      @include position(null, .5rem, 0);
    }
    .text {
      @extend %white;
      @extend %font-14-pb;
      @extend %uppercase;
    }
  }
  .player {
    &-image {
      width: 42%;
      .image {
        aspect-ratio: 3/4;
        object-fit: contain;
        @extend %relative;
      }
    }
    &-position {
      @include position(2rem, null, null, 31%);
      @extend %flex;
      .text {
        font: 700 7rem/1 $font-primary;
        letter-spacing: -.06em;
        -webkit-text-stroke: .1rem hsl(var(--hsl-light-silver) / .1);
        @extend %white-1;
      }
    }
    &-data {
      grid-area: a;
    }
    &-name {
      margin-bottom: .7rem;
      line-height: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      z-index: 1;
      @extend %relative;
      @extend %white;
      @extend %font-18-pr;
      @extend %uppercase;
      @extend %hidden;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .stats-swiper {
    @extend %pr-3;
  }
  .team-card {
    height: var(--_team-card-height);
    margin-top: -20%;
    display: grid;
    background: hsl(var(--hsl-back-drop) / .53);
    box-shadow: 0 .4rem .4rem 0 hsl(var(--hsl-black) / .25);
    gap: var(--space-8) var(--space-3);
    grid-template-areas: 'a b'
      'c c';
    grid-template-columns: calc(100% - 11rem - var(--space-3)) 11rem;
    isolation: isolate;
    @extend %p-4-3;
    @extend %half-radius;
    &::before {
      content: "";
      width: 100%;
      height: var(--_team-card-height);
      z-index: map-get($map: $zindex, $key: negative);
      backdrop-filter: blur(5rem);
      pointer-events: none;
      @extend %position-b-l;
    }
  }
  .club {
    &-wrap {
      grid-area: b;
      flex-direction: row-reverse;
      @extend %flex-n-c;
      @extend %gap-1;
    }
    &-logo {
      width: var(--_logo-width);
      min-width: var(--_logo-width);
      aspect-ratio: 1;
      @extend %p-1;
      @extend %circle-radius;
      @extend %white-bg;
    }
    &-name {
      @extend %white;
      .name {
        line-height: 1.4;
        z-index: 1;
        @extend %relative;
        @extend %white;
        @extend %font-10-pb;
        @extend %text-right;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .stats-data {
    &-list {
      grid-area: c;
      @extend %flex-n-fe;
      @extend %relative;
      &::before {
        content: "";
        height: .05rem;
        pointer-events: none;
        @include position(-2rem, null, null, 0);
        @extend %w-100;
        @extend %white-bg-2;
      }
    }
    &-item {
      max-width: 9rem;
      width: fit-content;
      flex-grow: 1;
      @extend %flex-column;
      @extend %gap-1;
      @extend %text-center;
      &:not(:last-child) {
        @extend %relative;
        &::after {
          content: "";
          width: .05rem;
          height: 100%;
          pointer-events: none;
          @include position-combo("br");
          @extend %white-bg-7;
        }
      }
    }
    &-label,
    &-value {
      line-height: 1;
    }
    &-label {
      @extend %font-10;
      @extend %club-accent-7;
      @extend %uppercase;
    }
    &-value {
      @extend %white;
      @extend %font-22-pb;
    }
    &-more {
      @include position-combo(inset);
    }
    &-link {
      @extend %h-100;
      @extend %d-block;
      @extend %font-0;
    }
  }
  .last-name {
    font-weight: 700;
  }
  .role {
    line-height: 1;
    @extend %white;
    @extend %font-12;
    @extend %uppercase;
  }
  // Pagination styles
  .swiper-pagination {
    height: .7rem;
    position: unset;
    @extend %mt-4;
    &-bullet {
      width: .5rem;
      height: .5rem;
      transition: width .3s ease-in, height .3s ease-in;
      &-active {
        width: .7rem;
        height: .7rem;
      }
    }
  }
  // Positioning for Golden Glove stats
  .stats-golden-glove {
    .stats-label::after {
      width: var(--_glove-width);
      aspect-ratio: var(--_glove-ar);
      transform: rotate(2.5deg);
      background-image: url('/static-assets/images/cssimages/hand.png?v=#{$image-version}');
    }
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .waf-home-awards {
    .player-position {
      top: 0;
    }
    .stats-data-value {
      font-size: 1.8rem;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-home-awards {
    padding-block: var(--space-10) var(--space-14);
    background-image: url("/static-assets/images/cssimages/home-page/awards-bg.png?v=#{$image-version}");
    &::before {
      width: 30.16%;
      top: unset;
      bottom: -16.4%;
      left: -11.64%;
    }
    &::after {
      content: "";
      width: 30.16%;
      aspect-ratio: .87;
      z-index: map-get($map: $zindex, $key: negative);
      background: url('/static-assets/images/svg/hexagon.svg?v=#{$image-version}') center / contain no-repeat;
      filter: invert(100%) sepia(6%) saturate(177%) hue-rotate(275deg) brightness(118%) contrast(100%);
      pointer-events: none;
      opacity: .05;
      @include position(-19%, -16.84%);
    }
    .waf-head .title {
      font-size: 2rem;
    }
    .head-wrap {
      gap: var(--space-2);
      justify-content: unset;
    }
    .head-tab {
      padding-left: 0;
      position: unset;
      a {
        height: 2.6rem;
        padding: var(--space-1) var(--space-2);
        border-radius: var(--half-radius);
        color: var(--white);
        font-size: 0;
        gap: 0;
        transition: 300ms ease-in-out;
        @include bg(primary);
        &:hover {
          padding: var(--space-1) var(--space-3);
          gap: var(--space-1);
          font-size: 1.1rem;
          color: var(--white);
        }
        &::after {
          width: unset;
          height: unset;
          border-radius: 0;
        }
      }
    }
    .stats {
      &-label {
        padding-left: var(--space-6);
      }
      &-list {
        @include card-count(2, 2rem);
      }
    }
    .swiper-container-initialized, .swiper-initialized {
      .stats-list {
        gap: 0;
        overflow-x: unset;
      }
    }
    .club-name {
      display: block;
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-home-awards {
    --_logo-width: 5.6rem;
    --_team-card-height: 22rem;
    --_boot-width: 8.9rem;
    --_glove-width: 6.4rem;
    --_stats-label-height: 4.7rem;
    .waf-head {
      margin-bottom: 2.3rem;
    }
    .stats-swiper {
      padding-right: 0;
      overflow-y: visible;
      overflow-x: clip;
    }
    .player {
      &-image {
        width: 45.7%;
      }
      &-position {
        top: unset;
        bottom: calc(var(--_team-card-height) + var(--_stats-label-height));
        left: 35.07%;
        .text {
          font-size: 14rem;
          -webkit-text-stroke-width: .23rem;
        }
      }
      &-name {
        font-size: 2.6rem;
      }
    }
    .stats-label {
      width: 48.87%;
      min-width: 27rem;
      height: var(--_stats-label-height);
      padding-left: var(--space-10);
      left: 35.07%;
      &::after {
        right: -2rem;
      }
      .text {
        font-size: 2rem;
      }
    }
    .team-card {
      padding: var(--space-5);
      border-radius: .7rem;
      margin-top: -25%;
      gap: var(--space-11) var(--space-3);
      box-shadow: 0 .56rem .56rem 0 hsl(var(--hsl-black) / .25);
      grid-template-columns: calc(100% - 18rem - var(--space-3)) 18rem;
    }
    .club {
      &-wrap {
        gap: var(--space-2);
      }
      &-name .name {
        font-size: 1.6rem;
      }
    }
    .role {
      font-size: 1.4rem;
    }
    .stats-data {
      &-list::before {
        top: -2.75rem;
      }
      &-item {
        max-width: unset;
        &:not(:last-child)::after {
          background-color: hsla(var(--hsl-mid-grey) / .5);
        }
      }
      &-label {
        font-size: 1.4rem;
      }
      &-value {
        font-size: 3rem;
      }
    }
    .stats-golden-glove .stats-label::after {
      right: .5rem;
    }
  }
}
@media screen and (min-width: $large-desktop-min-width) {
  .waf-home-awards {
    .player-position {
      left: 38%;
    }
    .team-card {
      margin-top: -26%;
    }
  }
}