@use '../config/' as *;
.waf-row-home-club-stats {
    isolation: isolate;
    @extend %relative;
    @extend %medium-grey-bg;
    @extend %hidden;
    @extend %more-btn-position;
    .waf-component {
        padding: var(--space-4) 0 var(--space-4) var(--space-3);
        @extend %mx-2-neg;
    }
    .title {
        @extend %mb-0;
    }
    &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        position: absolute;
        @extend %w-50;
        @extend %h-60;
        @extend %position-t-r;
    }
    .head-tab {
        bottom: 0.9rem;
        right: 1rem;
        .tabs-anchor {
            @extend %secondary-light;
        }
    }
    .team-card {
        height: 26rem;
        background: linear-gradient(180deg, var(--club-primary) 0%, var(--club-secondary) 96.04%);
        @extend %w-100;
        @extend %relative;
        @extend %flex;
        @extend %hidden;
        @extend %half-radius;
        &:after {
            content: "";
            position: absolute;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @extend %w-100;
            @extend %h-100;
            @include bgImg("cssimages/club-bg.png");
        }
    }
    .stats-swiper {
        @extend %pr-3;
    }
    .swiper-initialized, .swiper-container-initialized {
        .stats-list {
            gap: 0;
            overflow: unset;
        }
    }
    .stats-list {
        @include card-count(1.15, var(--space-3));
        .stats-label {
            position: absolute;
            top: 2rem;
            z-index: var(--z-footer);
            top: 2rem;
            @include ribbon(white, left);
            left: calc(50% - 0.9rem);
            @extend %flex-c-c;
            @extend %text-center;
            @extend %py-0;
            @extend %mb-0;
        }
        p {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            @extend %uppercase;
            @extend %secondary-dark;
        }
    }
    h2 {
        @extend %mb-0;
    }
    .club-wrap,
    .stats-data-list {
        flex: 0 0 50%;
        @extend %flex-column-c-c;
    }
    .club {
        &-wrap {
            border-radius: var(--half-radius) 0 0 var(--half-radius);
            z-index: var(--z-listing);
            @extend %px-1;
            @extend %text-center;
        }
        &-img {
            width: 10rem;
            height: 10rem;
            aspect-ratio: 1 / 1;
            filter: drop-shadow(0 0 0.4rem hsl(var(--hsl-black) / 0.10));
            @extend %p-4;
            @extend %circle-radius;
            @extend %white-bg;
        }
        &-name {
            line-height: 2.5rem;
            height: 7rem;
            @extend %white;
            @extend %font-16;
            @extend %py-3;
            @extend %flex-c-c;
            .name {
                @extend %white;
                @extend %transition;
                &:hover {
                    text-decoration: underline;
                }
            }
            .short-name {
                @extend %d-none;
            }
        }
    }
    .waf-head {
        @extend %mb-3;
    }
    .stats-data {
        &-list {
            @extend %half-radius;
            @extend %secondary-dark-bg;
            @extend %text-center;
        }
        &-item {
            height: 9rem;
            z-index: var(--z-listing);
            @include flex-config(flex, column-reverse);
            @extend %white;
            @extend %relative;
            &:not(:last-child) {
                &:after {
                    content: "";
                    height: 0.1rem;
                    bottom: -1rem;
                    position: absolute;
                    @extend %mid-grey-bg-5;
                    @extend %w-100;
                }
            }
        }
        &-more {
            position: absolute;
            bottom: 2rem;
            line-height: 1;
            z-index: var(--z-listing);
            @extend %flex-c-c;
            @extend %w-50;
        }
        &-link {
            @extend %stats-more;
        }
        &-value {
            @extend %font-34-pb;
        }
        &-label {
            @extend %white-7;
            @extend %uppercase;
            @extend %font-12-pm;
        }
    }
}
@include mq(col-md) {
    .waf-row-home-club-stats {
        background: var(--white);
        &:after {
            content: "";
            width: 40%;
            height: 100%;
        }
        .waf-component {
            padding-left: 0;
        }
        .waf-head {
            margin-bottom: var(--space-3);
        }
        .stats-swiper {
            padding-right: 0;
        }
        .stats-list {
            @include card-count(2, var(--space-3));
        }
        .stats-list-wrap {
            margin-inline: 0;
        }
        .club-name {
            display: flex;
        }
        .club {
            &-wrap {
                padding-inline: var(--space-3);
            }
        }
        h4 {
            font-size: 1.6rem;
        }
        .head-wrap {
            gap: var(--space-2);
            @include flex-config(flex, row, flex-start, center);
        }
        .head-tab {
            position: static;
            a {
                padding: var(--space-1) var(--space-2);
                border-radius: var(--half-radius);
                font-size: 0;
                gap: var(--space-0);
                transition: 300ms ease-in-out;
                color: var(--white);
                @include bg(primary);
                &:hover {
                    padding: var(--space-1) var(--space-3);
                    gap: var(--space-1);
                    font-size: 1.1rem;
                    color: var(--white);
                }
                &::after {
                    width: unset;
                    height: unset;
                    border-radius: 0;
                }
            }
        }
        .stats-item {
            width: 32%;
        }
        .swiper-slide {
            scale: unset;
        }
    }
}
@media screen and (max-width: $tablet-max-width) {
    .waf-statslisting {
        &.blue-btn-text {
            .head-tab {
                li a {
                    color: var(--secondary);
                }
            }
        }
    }
}