@use "../config" as *;
.waf-standings, .waf-scorestrip {
    .title {
        font-size: 1.8rem;
        @extend %white;
        @extend %mb-3;
      }
      &.waf-component{
          @extend %m-0;
          @extend %px-0;
          @extend %pb-0;
      }
      .layout-wrapper {
          @extend %relative;
      }
      .head-tab {
        z-index: var(--z-home-btn);
          @include position(null, 0, -4.5rem);
          a {
              @extend %gap-1;
              @extend %flex;
              @extend %more-btn;
          }
      }
}
.waf-standings{
    .head-tab{
        bottom: 2.5rem;
    }
}
  @media (min-width:$tablet-min-width) {
    .waf-standings, .waf-scorestrip {
        padding: 0;
        .head-tab {
            position: static;
            width: 100%;
            padding-left: var(--space-0);
            a {
                width: 100%;
                height: 100%;
                text-align: center;
                padding: var(--space-2) var(--space-4);
                border-radius: var(--quarter-radius);
                border: 0.1rem solid hsl(var(--hsl-shimmer-light) / 0.3);
                align-items: center;
                justify-content: center;
                &::after {
                    display: none;
                }
            }
            li {
                border: 0; 
            }
        }
        .waf-head {
            .title {
                display: none;
            }
        }
        .layout-wrapper{
            gap: var(--space-3);
            @include flex-config(flex, column-reverse, null, null);
        }
    }
  }