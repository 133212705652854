@use '../config/' as *;
.waf-row-home-tracker {
    position: relative;
    background: url("/static-assets/images/cssimages/home-page/mobile/tracker-bg.jpg?v=#{$image-version}") top/cover no-repeat,linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%);
    // @include background(linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%));
    isolation: isolate;
    overflow: hidden;
    .waf-tracker {
        @extend %p-6-0;
    }
    &::before,
    &::after {
        content: '';
        width: 30%;
        min-width: 30rem;
        aspect-ratio: 1/1;
        z-index: var(--z-negative);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        opacity: 0.1;
        pointer-events: none;
        filter: brightness(0) invert(1);
    }
    &::before {
        transform: translate(50%, -50%);
        @extend %position-t-r;
    }
    &::after {
        left: -23%;
        @extend %position-y-center;
        @extend %d-none;
    }
}
.waf-tracker {
    .head {
        &-wrap {
            justify-content: center;
            .title {
                @extend %accent-bg;
                @extend %rounded-radius;
                @extend %p-1-10;
                @extend %font-16;
                @extend %text-center;
            }
        }
        &-tab {
            @extend %d-none;
        }
    }
}
.tracker {
    &-list {
        @extend %flex-c-n;
        @extend %flex-wrap;
        .total-goals {
            .tracker {
                &-data {
                    @extend %w-100
                }
                &-number {
                    flex-direction: column;
                    @extend %pl-0;
                    &::before {
                        width: 30rem;
                        height: 10rem;
                        max-width: calc(var(--window-inner-width) - 5rem);
                        @extend %mr-0;
                        @extend %mb-10-neg;
                    }
                }
            }
        }
    }
    &-item {
        @extend %white;
        @extend %uppercase;
        @extend %flex-c-fs;
        @extend %p-4;
    }
    &-number {
        @extend %pl-6;
        @extend %pb-2;
        @extend %font-30-pb;
        @extend %flex-c-c;
        &::before {
            content: "";
            width: 5rem;
            height: 5rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @extend %mr-4-neg;
        }
    }
    &-data {
        @extend %text-center;
        .text {
            @extend %font-10-pb;
        }
    }
    &-text {
        width: max-content;
        border-radius: 4px;
        background: linear-gradient(90deg, transparent 0.04%, clr(white, 1.5) 53.64%, transparent 99.96%);
        @extend %mx-auto;
        @extend %p-1-4;
    }
}
$tracker: (
    "games-played",
    "shots",
    "clean-sheet",
    "recoveries",
    "interceptions",
    "total-goals",
    "assists",
    "total-successfull-passes",
    "duels-won",
    "tackles-won",
    "yellow-cards",
    "red-cards",
);
@each $tracker-item in $tracker {
    .#{$tracker-item} {
        .tracker-number::before {
            @include bgImg("svg/tracker/#{$tracker-item}.svg")
        }
    }
}
.waf-tracker {
    .m-right-seperator {
        @include separator(50%, y);
    }
}
.attack-zone {
    display: block;
    @extend %w-100;
    .tracker {
        &-image {
            @extend %w-100;
        }
        &-img {
            height: unset;
            @extend %w-100;
        }
        &-title {
            @extend %font-16-pr;
            @extend %text-center;
            .text {
                @extend %font-16;
            }
        }
        &-data {
            @extend %flex-wrap;
            @extend %flex-sb-n;
            @extend %mt-4-neg;
            &-center {
                @extend %mt-10-neg;
            }
            &::before {
                width: 100%;
                aspect-ratio: 19/6;
                content: "";
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                @include bgImg("svg/tracker/attack-zone.svg");
            }
        }
        &-number {
            @extend %p-0;
            @extend %mb-0;
            &::before {
                all: unset;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-tracker {
        padding-block: 5rem;
        .m-right-seperator{
            &::after{
                display: none;
            }
        }
        .right-seperator{
            @include separator(50%, y);
            &::after{
                display: block;
            }
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-row-home-tracker {
        &::after {
            display: block;
        }
    }
    .waf-tracker {
        .title {
            font-size: 1.2rem;
        }
    }
    .tracker {
        &-list{
            gap: var(--space-3) 0;
            align-items: flex-end;
        }
        &-number {
            font-size: 4rem;
            &::before {
                width: 7rem;
                height: 7rem;
            }
        }
        &-data {
            .text {
                font-size: 1.6rem;
            }
        }
        &-title {
            font-size: 2rem;
        }
        &-list{
            .total-goals{
                .tracker-number::before{
                    width: 57rem;
                    height: 27rem;
                    margin-bottom: -12rem;
                }
            }
        }
    }
}