@use "../config" as *;
// html {
.load-bg-pattern {
  .waf-takeover-scorestrip {
    .card-item-wrapper {
      background: url(/static-assets/images/cssimages/home-page/mobile/homepage-takeover-bg.png?v=#{$image-version}) center / cover no-repeat;
      &.live::after {
        @include bgImg("cssimages/live-pattern.png");
        background-size: cover;
      }
    }
    .waf-body {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top;
      overflow: hidden;
      // @include bgImg("stadium/takeover/takeover.png");
      @each $venue in $venueID {
        .venue-#{$venue} {
          position: relative;
          &::after {
            @include bgImg("club/venue/takeover/mobile/#{$venue}.jpg");
          }
        }
      }
    }
  }
}
.waf-takeover-scorestrip {
  --swiper-navigation-sides-offset: 1.5rem;
  line-height: 1;
  &.waf-component {
    @extend %p-0;
    @extend %mb-0;
  }
  .layout-wrapper {
    @extend %h-100;
  }
  .waf {
    &-head {
      @extend %d-none;
    }
    &-body {
      height: calc(var(--window-inner-height) - var(--header-height));
      padding: var(--space-3) var(--space-3) var(--space-8);
      position: fixed;
      z-index: var(--z-strip-modal);
      inset: var(--header-height) 0 0;
      overflow: auto;
      flex-direction: column;
      gap: 4%;
      opacity: 0;
      transform: translateY(100%);
      @extend %w-100;
      @extend %flex;
      @extend %back-drop-bg;
      @extend %transition;
      // .team-client {
      //   @extend %d-none;
      // }
      .swiper {
        overflow: unset;
        @extend %mx-3-neg;
        @extend %px-3;
      }
    }
  }
  .dependancy-wrapper {
    height: 33%;
    @extend %mt-0;
  }
  .score-list-section {
    margin-top: auto;
    height: 63%;
    min-height: 35rem
  }
  .swiper-pagination {
    @extend %d-none;
  }
  .card {
    &-list-item {
      overflow-y: visible;
      overflow-x: clip;
      @extend %h-100;
    }
    &-item-wrapper {
      padding: var(--space-6) var(--space-3) var(--space-4);
      gap: 5%;
      display: grid;
      align-items: center;
      justify-content: center;
      @extend %h-100;
      @extend %quarter-radius;
      @extend %secondary-bg;
      @extend %relative;
      &::after {
        content: "";
        background-size: cover;
        background-position: top center;
        width: var(--window-inner-width);
        height: calc(var(--window-inner-height) - var(--header-height));
        inset: unset;
        position: absolute;
        right: -1.5rem;
        bottom: -4rem;
        z-index: calc(var(--z-negative) * 3);
      }
      &.recent {
        .won {
          .score {
            @extend %accent;
          }
        }
        .team-time-status {
          background: linear-gradient(114deg, var(--secondary-dark) 0%, var(--secondary-dark) 47.56%, var(--primary) 100%);
        }
      }
      &.live {
        // isolation: isolate;
        &::before {
          content: "";
          background: linear-gradient(-45deg, var(--primary), var(--secondary-dark), var(--primary)) left center / 200% 200% no-repeat;
          animation: gradient-bg 20s ease infinite;
          opacity: 0.7;
          z-index: var(--z-gradient-live);
          @extend %position-center;
        }
        > * {
          z-index: var(--z-takeover-content);
        }
        .current {
          .score {
            @extend %white;
          }
        }
      }
      &.upcoming {
        .team-time-status {
          width: 13rem;
          height: 2.7rem;
          @extend %secondary-bg;
          .text {
            font-size: 1.2rem;
            &::before {
              display: inline-block;
              @include icon(clock, 12);
              @extend %mr-1;
            }
          }
        }
        .team-score {
          // @extend %d-none;
          .score {
            display: none;
          }
        }
      }
      &.postponed {
        .team-time-status {
          @extend %dark-grey-bg;
          .text::before {
            display: none;
          }
        }
      }
    }
    &-number {
      width: max-content;
      height: 2.6rem;
      position: absolute;
      top: 0;
      left: 50%;
      translate: -50% -50%;
      @extend %p-1-5;
      @extend %rounded-radius;
      @extend %flex-n-c;
      @extend %white-bg;
      @extend %text-center;
      .text {
        font-weight: 700;
        @extend %font-11;
        @extend %uppercase;
      }
    }
    &-head {
      @extend %mx-auto;
      @extend %p-1-3;
      @extend %half-radius;
      @extend %secondary-dark-bg-3;
    }
    &-meta {
      @extend %text-center;
    }
    &-body {
      padding-bottom: 4rem;
      border-bottom: .1rem solid hsl(var(--hsl-white) / .1);
      display: grid;
      gap: var(--space-2);
      grid-auto-flow: column;
      grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    }
    &-action {
      margin-top: auto;
      @extend %flex-wrap;
      @extend %gap-3;
      @extend %flex-c-c;
      .btn {
        &-site {
          width: 12rem;
          @extend %p-1-2;
          @extend %flex-c-c;
          @extend %gap-1;
          @extend %quarter-radius;
          @extend %hidden;
          .text {
            font-size: 1.2rem;
          }
        }
        &-ticket {
          background: transparent;
          @include border(1, white, 7);
          @extend %white;
          &::before {
            @include icon(ticket, 10);
          }
        }
        &-preview,
        &-report {
          background-color: transparent;
          @include border(1, white, 7);
          @extend %white;
          &::before {
            @include icon(file, 10);
          }
        }
        &-preview,
        &-report,
        &-highlight {
          background-color: transparent;
          @include border(1, white, 7);
          @extend %white;
          &::before {
            @include icon(file, 10);
          }
        }
        &-highlight {
          &::before {
            @include icon(play, 10);
          }
        }
      }
    }
    &-client {
      width: 100%;
      order: -1;
      @extend %mb-4;
      .link {
        width: 17rem;
        margin-inline: auto;
        gap: var(--space-1);
        @extend %flex-c-c;
        &::before {
          content: "Showing On";
          padding: var(--space-1) .7rem;
          display: inline-block;
          font-weight: 700;
          white-space: nowrap;
          @extend %rounded-radius;
          @extend %success-bg;
          @extend %white;
          @extend %font-9;
          @extend %uppercase;
        }
      }
    }
  }
  .team {
    gap: var(--space-2);
    @extend %flex-fe-fs;
    &-b {
      flex-direction: row-reverse;
    }
    &-image {
      @extend %mb-2;
      .image {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
    &-name {
      @extend %mb-0;
      @extend %text-center;
      @extend %white;
      &:hover {
        text-decoration: underline;
      }
    }
    &-score {
      width: 3rem;
      @extend %h-100;
      @extend %flex-c-c;
    }
    &-status {
      width: 10rem;
      @extend %h-100;
      @extend %flex-c-c;
    }
    &-time-status {
      width: 6rem;
      height: 2.5rem;
      border: .1rem solid hsl(var(--hsl-white) / .3);
      @extend %rounded-radius;
      @extend %flex-c-c;
      @extend %secondary-dark-bg;
      .text {
        width: max-content;
        font-size: 1rem;
        @extend %white;
      }
    }
    &-client {
      @include position(null, null, 6.5rem, 0);
      @extend %w-100;
      .image {
        height: 3.3rem;
      }
      .link {
        width: 17rem;
        margin-inline: auto;
        gap: var(--space-1);
        @extend %flex-c-c;
        &::before {
          content: "Showing On";
          padding: var(--space-1) .7rem;
          display: inline-block;
          font-weight: 700;
          white-space: nowrap;
          @extend %rounded-radius;
          @extend %success-bg;
          @extend %white;
          @extend %font-9;
          @extend %uppercase;
        }
      }
    }
  }
  .meta {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    @extend %white;
    &:first-child::after {
      content: "|";
      @extend %mx-1;
    }
  }
  .time::before {
    display: inline-block;
    @include icon(clock, 10);
    @extend %mr-1;
  }
  .venue {
    @extend %d-block;
    &::before {
      @include icon(stadium, 10);
      @extend %mr-1;
    }
  }
  .full,
  .match,
  .btn-ticket {
    @extend %d-none;
  }
  .short {
    font-weight: 700;
    @extend %white;
    @extend %font-16;
  }
  .image {
    object-fit: contain;
    object-position: center;
    @extend %w-100;
    @extend %h-100;
  }
  .score {
    font-weight: 700;
    @extend %white-5;
    @extend %font-26;
  }
  .btn-site {
    display: inline-block;
  }
  .btn-matchcenter {
    @extend %px-9;
    @extend %capitalize;
  }
}
.scorestrip-toggle {
  height: var(--scorestrip-toggle-height);
  padding-inline: var(--space-2) var(--space-4);
  position: fixed;
  z-index: var(--z-strip);
  left: 0;
  bottom: var(--quicklink-head-height);
  cursor: pointer;
  gap: var(--space-2);
  @extend %w-100;
  @extend %flex-c-c;
  @extend %accent-light-bg;
  &.live {
    &::before {
      content: "live";
      padding: .3rem var(--space-2);
      display: inline-block;
      font-weight: 700;
      @extend %quarter-radius;
      @extend %success-bg;
      @extend %white;
      @extend %font-10;
      @extend %uppercase;
    }
  }
  &::after {
    margin-left: auto;
    display: inline-block;
    animation: opennow 2s alternate infinite;
    @include icon(double-chevron-up, 12);
  }
  &[aria-expanded='true'] {
    width: 6rem;
    height: 2rem;
    padding-inline: 0;
    border-top-left-radius: var(--half-radius);
    border-top-right-radius: var(--half-radius);
    z-index: var(--z-open-strip);
    left: 50%;
    bottom: 0;
    translate: -50% 0;
    &::after {
      margin-left: unset;
      rotate: 180deg;
    }
    &::before {
      display: none;
    }
    &::before,
    .btn-text,
    .title {
      @extend %d-none;
    }
    ~ .waf-body {
      transform: translateY(0%);
      opacity: 1;
      pointer-events: initial;
    }
  }
  .btn-text,
  .title {
    font-weight: 500;
    @extend %mb-0;
    @extend %font-12;
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .waf-takeover-scorestrip {
    .score {
      font-size: 2rem;
    }
    .short {
      font-size: 1.4rem;
    }
    .team {
      &-image {
        .image {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
      &-time-status {
        width: 5.5rem;
      }
      &-client {
        bottom: 6.5rem;
      }
    }
    .card-item-wrapper {
      padding: var(--space-4) var(--space-2) var(--space-3);
      &.upcoming .team-time-status {
        width: 10.5rem;
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .load-bg-pattern {
    // html {
    .waf-takeover-scorestrip {
      .card-item-wrapper {
        @include bgImg("cssimages/home-page/homepage-takeover-bg.png");
      }
      .waf-body {
        overflow: hidden;
        @each $venue in $venueID {
          .venue-#{$venue} {
            position: relative;
            &::after {
              @include bgImg("club/venue/takeover/#{$venue}.jpg");
            }
          }
        }
      }
    }
  }
  .waf-takeover-scorestrip {
    .layout-wrapper {
      max-width: unset;
      width: 100%;
      margin: unset;
    }
    .dependancy-wrapper {
      width: unset;
      position: unset;
    }
    .waf-body {
      padding-top: var(--space-5);
      transform: translateY(-100%);
      pointer-events: none;
      transition: 500ms;
      top: var(--header-height);
    }
    .card {
      &-item-wrapper {
        padding-block: var(--space-12) var(--space-8);
        align-items: center;
      }
      &-head,
      .card-meta {
        width: fit-content;
      }
      &-body {
        padding-bottom: 0;
        border-bottom: 0;
        align-items: center;
        gap: var(--space-5);
      }
      &-action {
        .btn-site {
          width: 17rem;
          border-radius: var(--half-radius);
          padding: var(--space-2) var(--space-4);
          &::before {
            font-size: 1.4rem;
          }
          .text {
            font-size: 1.4rem;
          }
        }
      }
      &-client {
        .link {
          width: 21rem;
        }
      }
    }
    .team {
      align-items: center;
      gap: var(--space-6);
      &-info {
        gap: var(--space-4);
        @include flex-config(flex, null, null, center);
      }
      &-image {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        margin-bottom: 0;
        background-color: hsl(var(--hsl-white) / .7);
        @include flex-config(flex, null, center, center);
      }
      &-a {
        .team-info {
          flex-direction: row-reverse;
        }
      }
      &-score {
        width: unset;
        height: unset;
      }
      &-status {
        height: unset;
      }
      &-time-status {
        width: 10rem;
        height: 4rem;
        .text {
          font-size: 1.8rem;
          &::before {
            font-size: 1.6rem;
          }
        }
      }
      &-client {
        bottom: 9rem;
      }
    }
    .venue {
      display: inline-block;
    }
    .meta:not(:last-child)::after {
      content: "|";
      margin-inline: var(--space-1);
    }
    .meta {
      font-size: 1.2rem;
    }
    .score {
      font-size: 5rem;
    }
    .btn-matchcenter {
      .text {
        font-size: 1.6rem;
      }
    }
    .swiper-button-prev {
      top: unset;
      right: 7rem;
      bottom: 3rem;
      left: unset;
    }
    .swiper-button-next {
      top: unset;
      right: 3rem;
      bottom: 3rem;
      left: unset;
    }
  }
  .scorestrip-toggle {
    padding: 0;
    top: var(--header-height);
    bottom: unset;
    background-color: var(--secondary-dark);
    color: var(--white);
    &::after {
      content: "\e825";
      margin-left: unset;
    }
    &[aria-expanded='true'] {
      top: unset;
      background-color: var(--accent-light);
      color: var(--secondary-dark);
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-takeover-scorestrip {
    .score {
      font-size: 7rem;
    }
    .short {
      display: none;
    }
    .full {
      display: unset;
      color: var(--white);
      font-size: 2.4rem;
      text-transform: uppercase;
    }
    .team-image {
      width: 10rem;
      height: 10rem;
      .image {
        width: 9rem;
        height: 9rem;
        padding: var(--space-2);
      }
    }
    .team-time-status {
      width: 13rem;
      height: 4.5rem;
      .text {
        font-size: 2.2rem;
        &::before {
          font-size: 2rem;
        }
      }
    }
  }
}