@use "../config/" as *;
.waf-row-home-showcase {
    .row {
        @extend %common-article-content;
        & > div {
            @extend %p-0;
        }
    }
    .layout-wrapper {
        // margin-bottom: 5rem;
        max-width: 100%;
    }
    .waf-showcase {
        @extend %p-0;
        @extend %m-0;
        @extend %relative;
    }
    .img-box {
        aspect-ratio: 1/1;
    }
}
.dependancy-wrapper {
    .accordion {
        &-body {
            @extend %relative;
            @extend %hidden;
            @extend %secondary-dark-bg;
        }
    }
}
@media only screen and (min-width: $small-mobile-min-width) and (max-width: $mobile-max-width) {
    .waf-row-home-showcase {
        margin-top: calc((var(--window-inner-width) * -2) - var(--extra-video-space));
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-home-showcase {
        order: -1;
        /* home page fixture and standing start*/
        .tab {
            &-name {
                border: 0.1rem solid hsl(var(--hsl-secondary) / 1);
            }
        }
        .img-box {
            aspect-ratio: unset;
            width: 100%;
            height: calc(var(--window-inner-height) - var(--header-height));
        }
        .dependancy-wrapper {
            &.active {
                right: 0;
            }
            .accordion {
                &-body {
                    width: 43rem;
                    height: calc(100vh - var(--header-height));
                    @include position(var(--header-height), -43rem, null, null, fixed);
                }
                &-btn {
                    transform: translateY(-50%);
                    @include position(calc(var(--header-height) + ((var(--window-inner-height) - var(--header-height)) / 2)), 0, null, null, fixed);
                    &[aria-expanded="true"] {
                        right: 43rem;
                        + .accordion-body {
                            right: 0;
                        }
                    }
                    .btn-text::after {
                        animation: opennowleft 2s alternate infinite;
                    }
                }
            }
        }
        /* home page fixture and standing end*/
        .layout-wrapper {
            margin-bottom: 0;
        }
    }
}
@media only screen and (min-width: $small-mobile-min-width) and (max-width: $mobile-max-width) {
    .showcase-wrapper {
        padding-bottom: var(--showcase-video-space);
    }
}