@use "../config/" as *;
@forward "../swiper/swiper-bundle";
.waf-showcase:where(:not(.trending-showcase)) {
    .preview-swiper {
        @extend %relative;
    }
    .article {
        &-list {
            @extend %h-100;
        }
        &-item {
            border-radius: 0;
            @extend %relative;
            @extend %h-100;
            &:after {
                content: '';
                height: 30rem;
                pointer-events: none;
                background: linear-gradient(180deg, hsl(var(--hsl-secondary-light)/0) 0%, hsl(var(--hsl-secondary-dark)/ 0.2) 22.38%, var(--secondary-dark) 100%);
                @extend %w-100;
                @extend %position-b-l;
            }
        }
        &-content {
            z-index: var(--z-showcase-content);
            pointer-events: none;
            display: flex;
            flex-wrap: wrap;
            @extend %white;
            @extend %p-0-4;
            @include position(null, null, 5rem, null);
            > a {
                @extend %white;
                @extend %w-100;
            }
        }
        &-meta {
            .meta {
                @extend %white-8;
            }
        }
        &-title {
            @include truncate(2, 20, 30, $font-primary);
        }
        &-thumbnail {
            @extend %relative;
            @extend %h-100;
        }
        &-wrap {
            @extend %h-100;
        }
    }
    .icon-time-wrapper {
        order: -1;
        margin-bottom: 2rem;
    }
    .item-type-icon {
        &::before {
            font-size: 4rem;
        }
    }
    .img-box {
        border-radius: 0;
        @extend %h-100;
    }
    .swiper {
        &-pagination {
            position: absolute;
            bottom: var(--space-8);
        }
        &-button-prev,
        &-button-next {
            top: auto;
            bottom: 20rem;
            @extend %d-none;
        }
        &-button-prev {
            left: var(--container-white-space);
        }
        &-button-next {
            right: auto;
            left: calc(var(--container-white-space) + var(--space-7));
        }
    }
    .dependancy-wrapper {
        .accordion {
            &-body {
                isolation: isolate;
                @extend %px-3;
                @extend %py-4;
                &::before {
                    content: "";
                    width: 23rem;
                    height: 27rem;
                    z-index: var(-z-negative);
                    background-size: contain;
                    filter: invert(1);
                    opacity: 0.1;
                    pointer-events: none;
                    @include position(-8rem, -10.3rem, null, null);
                }
            }
            &-btn {
                @extend %d-none;
            }
        }
    }
    .tabs {
        @extend %d-none;
        @extend %p-0;
    }
}
@media (min-width:$desktop-min-width) {
    .waf-showcase:where(:not(.trending-showcase)) {
        .article {
            &-content {
                max-width: 60%;
                padding: 0;
                left: var(--container-white-space);
                right: 0;
            }
        }
        .swiper {
            &-pagination {
                display: none;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-showcase:where(:not(.trending-showcase)) {
        .preview-swiper {
            height: calc(var(--window-inner-height) - var(--header-height)); //height will be change when collapsible strips present
        }
        .article {
            &-content {
                bottom: 5rem;
                .item-type-icon {
                    &::before {
                        font-size: 6rem;
                        margin-bottom: var(--space-4);
                        display: block;
                    }
                }
            }
            &-title {
                @include truncate(2, 42, 48);
            }
            &-meta {
                .meta {
                    font-size: 1.2rem;
                }
            }
        }
        .swiper {
            &-button-prev,
            &-button-next {
                display: flex;
            }
        }
        .dependancy-wrapper {
            .accordion {
                &-body {
                    z-index: var(--z-home-standing);
                    transition: 500ms ease-in-out;
                    background-color: hsl(var(--hsl-back-drop) / 0.8);
                    backdrop-filter: blur(1rem);
                    -webkit-backdrop-filter: blur(1rem);
                    box-shadow: -0.3rem 0 0.4rem 0 hsl(var(--hsl-black) / .25);
                    height: 100%;
                    &::before {
                        content: unset;
                    }
                }
                &-btn {
                    width: 3rem;
                    height: 18rem;
                    overflow: visible;
                    background-color: var(--accent);
                    transition: 300ms;
                    border-radius: var(--half-radius) 0rem 0rem var(--half-radius);
                    box-shadow: -0.2rem 0 0.3rem 0 hsl(var(--hsl-black) / .25);
                    transition: 500ms all ease-in-out;
                    z-index: var(--z-home-standing);
                    @include flex-config(flex, null, center, center);
                    .btn-text {
                        font-size: 1.4rem;
                        gap: var(--space-2);
                        text-transform: capitalize;
                        writing-mode: vertical-rl;
                        white-space: nowrap;
                        rotate: 180deg;
                        @include flex-config(flex, null, null, center);
                        &::after {
                            transition: rotate 500ms ease-in;
                            @include icon(double-chevron-right, 14);
                        }
                    }
                    &[aria-expanded="true"] {
                        .btn-text {
                            &::after {
                                rotate: 180deg;
                            }
                        }
                    }
                }
            }
        }
        .tabs {
            @include flex-config(flex);
        }
        .tab {
            &-anchor {
                width: 100%;
                height: 100%;
                text-align: center;
                padding: var(--space-2) var(--space-4);
            }
            &-name {
                flex-basis: 100%;
                width: 100%;
                list-style-type: none;
                border-radius: 0.2rem;
                border: 0.1rem solid hsl(var(--hsl-shimmer-light) / 0.3);
                .btn-text {
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: hsl(var(--hsl-white) / 1);
                }
                &.active {
                    border: 0;
                    background-color: hsl(var(--hsl-primary) / 1);
                }
                &:first-of-type {
                    border-right: 0;
                }
                &:last-of-type {
                    border-left: 0;
                }
            }
        }
    }
}