@use "../config" as *;
@forward "../showcase/common-showcase-dependency";
@forward "./common-club-home-standings";

.waf-standings {
    &::before{
        content: "";
        width: 23rem;
        height: 27rem;
        z-index: var(--z-negative);
        background-size: contain;
        filter: invert(1);
        opacity: 0.1;
        pointer-events: none;
        @include position(-13rem, null, null, -5rem);
    }
    .waf-body {
        @extend %secondary-light-bg;
    }
}

.standings-table{
    @extend %flex;
    .table{
        &-row-wrap {
            &::after {
                @extend %white-bg-1;
            }
        }
    }
}

.table-left {
    .table {
        &-body {
            @include bg(white, 0.3);
        }
    }
}

@media screen and (min-width: $tablet-min-width) {
    .standings-table{
        overflow-y: auto;
        height: calc(var(--window-inner-height) - 25rem);
    }
    .waf-standings {
        &::before{
            content: unset;
        }
        .waf-body {
            background: transparent;
        }
        .table {
            &-left{
                .table-body{
                    background-color: transparent;
                }
            }
            &-body {
                background: transparent;  
            }
        }
    }
}