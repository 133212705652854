@use "../config" as *;
.waf-fixtures,
.waf-scorestrip:not(.waf-takeover-scorestrip) {
  .card {
    &-item-wrapper {
      padding: 0 var(--space-2) var(--space-2);
      flex-shrink: unset;
      gap: var(--space-2);
      flex-direction: column-reverse;
      line-height: 1;
      @extend %flex;
      @extend %relative;
      &:not(:last-child) {
        @extend %mb-2;
      }
      &.recent {
        .team-a {
          .team-score {
            background: linear-gradient(104.94deg, var(--secondary-dark) 34.65%, rgb(78, 59, 90) 100%);
          }
        }
        .team-b {
          .team-score {
            background: linear-gradient(104.94deg, rgb(78, 59, 90) 34.65%, var(--primary));
          }
        }
        .score {
          @extend %white-7;
        }
        .won {
          .score {
            @extend %accent;
          }
        }
        .btn-tickets {
          @extend %d-none;
        }
      }
      &.live {
        background: linear-gradient(-45deg, var(--primary), var(--secondary-dark), var(--primary)) left center / 200% 200% no-repeat;
        box-shadow: 0 0 .3rem .3rem rgba(58, 64, 85, 0.15);
        animation: gradient-bg 20s ease infinite;
        @extend %half-radius;
        .team-score {
          background: var(--white);
          &::before {
            @extend %secondary-light-bg-5;
          }
        }
        .team-time-status {
          .text {
            @extend %accent;
          }
        }
        .venue,
        .short,
        .btn-matchcenter::before {
          @extend %white;
        }
        .btn-tickets {
          @extend %d-none;
        }
      }
      &.upcoming {
        .card-body {
          gap: var(--space-2);
          grid-template-areas: 'a b c';
          grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
        }
        .team-status {
          height: 2.6rem;
          background: var(--secondary);
          max-width: 11rem;
          margin-inline: 0rem;
          @extend %px-2;
          @extend %flex-c-c;
        }
        .team-score {
          display: none;
        }
        .team-status {
          .text {
            font-weight: 400;
            gap: .5rem;
            @extend %flex-n-c;
            @extend %white;
            @extend %font-12;
            &::before {
              display: inline-block;
              @include icon(clock, 12);
            }
          }
        }
      }
      &.postponed {
        .team-status {
          background-color: var(--dark-grey);
          .text::before {
            display: none;
          }
        }
      }
    }
    &-body {
      width: min(29.5rem, 92%);
      margin-inline: auto;
      align-items: center;
      display: grid;
      gap: 0;
      grid-template-areas: 'b b'
        'a c';
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @extend %relative;
    }
  }
  .team {
    gap: var(--space-2);
    @extend %flex-fe-c;
    &-info {
      gap: var(--space-1);
      flex-direction: row-reverse;
      @extend %flex-n-c;
    }
    &-image {
      .image {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
    &-name {
      z-index: var(--z-default);
      @extend %mb-0;
      @extend %relative;
      &:hover {
        text-decoration: underline;
      }
    }
    &-anchor {
      @extend %flex;
      &:hover {
        text-decoration: underline;
      }
    }
    &-status {
      grid-area: b;
      @extend %mx-4;
      @extend %quarter-radius;
    }
    &-a {
      grid-area: a;
      .team-score {
        border-top-left-radius: var(--quarter-radius);
        border-bottom-left-radius: var(--quarter-radius);
      }
    }
    &-b {
      grid-area: c;
      flex-direction: row-reverse;
      .team-score {
        border-top-right-radius: var(--quarter-radius);
        border-bottom-right-radius: var(--quarter-radius);
        @extend %relative;
        &::before {
          content: "";
          width: .1rem;
          height: calc(100% - var(--space-2));
          @include position-combo("y-center", null, 0);
          @extend %white-bg-5;
        }
      }
      .team-info {
        flex-direction: row;
      }
    }
    &-time-status {
      @extend %text-center;
      .text {
        font-weight: 700;
        @extend %font-10;
      }
    }
    &-score {
      min-width: 5.5rem;
      height: 2.6rem;
      @extend %px-1;
      @extend %flex-c-c;
      @extend %text-center;
    }
  }
  .short {
    font-size: 1.2rem;
  }
  .image {
    font-size: 0;
    object-fit: contain;
    object-position: center;
    @extend %w-100;
    @extend %h-100;
  }
  .score {
    font-weight: 700;
    @extend %font-14;
  }
  .btn-matchcenter {
    background-color: transparent;
    @include position-combo(inset);
    @extend %p-0;
    &::before {
      translate: 0 -50%;
      @include position(50%, var(--space-3));
      transform: rotate(90deg);
      transform-origin: center;
      @include icon(long-up-arrow, 16);
      @extend %secondary-light;
    }
    .text {
      @extend %d-none;
    }
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .waf-fixtures,
  .waf-scorestrip:not(.waf-takeover-scorestrip) {
    .card {
      &-item-wrapper {
        padding-inline: var(--space-2);
      }
      &-body {
        gap: var(--space-1) 0;
      }
    }
    .team {
      gap: .7rem;
      &-image {
        .image {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
}