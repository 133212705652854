@use "../config/" as *;
.waf-player-stats {
    @include bgImg("cssimages/home-page/hexagon-pattern-bg.svg");
    @extend %common-card-with-more-btn;
    &.waf-component {
        padding: var(--space-8) 0 var(--space-12) var(--space-3);
        @extend %my-0;
        @extend %mx-2-neg;
    }
    .head-tab {
        bottom: var(--space-4);
        right: var(--space-3);
        .tab-anchor {
            @extend %black;
        }
    }
    .swiper-initialized, .swiper-container-initialized {
        .stats-list {
            gap: 0;
            overflow-x: unset;
        }
    }
    .stats {
        &-swiper {
            overflow: hidden;
            @extend %pr-3;
        }
        &-list {
            @include card-count(1.15, 2.2rem);
        }
        &-item {
            height: auto;
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            background: linear-gradient(180deg, var(--club-primary) 0%, var(--club-secondary) 96.04%);
            @extend %relative;
            @extend %flex-column;
        }
        &-label {
            top: var(--space-4);
            z-index: var(--z-home-stats);
            @extend %uppercase;
            @extend %font-12-pb;
            @extend %fit-line-height;
            @include ribbon(white, right);
        }
        &-data {
            &-list {
                width: 70%;
                @extend %flex-c-fe;
                @extend %relative;
                &::after {
                    content: "";
                    width: 142.86%;
                    height: .1rem;
                    background-color: hsl(var(--hsl-white) / 0.1);
                    @include position(var(--space-3-neg), null, null, 0);
                }
            }
            &-label {
                @extend %text-center;
                @extend %white-7;
                @extend %font-10-pr;
                @extend %fit-line-height;
                @extend %uppercase;
            }
            &-value {
                @extend %font-28-pb;
                @extend %fit-line-height;
            }
            &-item {
                flex-basis: 100%;
                @extend %flex-column-c-c;
                @extend %gap-1;
                @include separator(90%, y, 0.1rem, white, 1);
            }
            &-link {
                @extend %stats-more;
            }
        }
    }
    .player {
        &-card {
            aspect-ratio: 16/9;
            border-top-left-radius: var(--half-radius);
            border-top-right-radius: var(--half-radius);
            @extend %relative;
        }
        &-image {
            @extend %w-50;
            @extend %position-b-l;
        }
        &-position {
            font-size: 10rem;
            @extend %font-pb;
            @extend %fit-line-height;
            @extend %secondary-dark-1;
            @include textstroke();
            @include position(null, var(--space-3), 1rem);
        }
        &-data {
            flex: 55%;
            @extend %gap-2;
            @extend %mb-4;
            @extend %flex-column-c-n;
        }
        &-name {
            @extend %white;
            @extend %gap-1;
            @extend %flex-column-c-n;
            @extend %uppercase;
            @extend %mb-0;
            @extend %transition;
            &:hover {
                text-decoration: underline;
            }
            .first-name {
                @extend %font-18-pr;
                @extend %fit-line-height;
            }
            .last-name {
                @extend %font-18-pb;
                @extend %fit-line-height;
            }
        }
        &-role {
            width: 7rem;
            @extend %py-1;
            @extend %accent-bg;
            @extend %quarter-radius;
            @extend %font-10-pr;
            @extend %fit-line-height;
            @extend %uppercase;
            @extend %secondary;
            @extend %text-center;
        }
    }
    .team-card {
        flex-wrap: wrap;
        z-index: var(--z-home-stats);
        aspect-ratio: 16/9;
        flex-grow: 1;
        @extend %gap-2;
        @extend %p-4;
        @extend %white;
        @extend %half-radius;
        @extend %relative;
        @extend %flex-sb-c;
        @extend %half-radius;
        &::before,
        &::after {
            content: '';
            @extend %half-radius;
        }
        &::before {
            inset: 0;
            -webkit-backdrop-filter: blur(2.5rem);
            backdrop-filter: blur(2.5rem);
            z-index: var(--z-home-stats-bg-before);
            @include position-combo(inset);
            @extend %secondary-dark-bg-6;
        }
        &::after {
            background-image: url(/static-assets/images/cssimages/blur-bg.png?v=#{$image-version});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: var(--z-home-stats-bg-after);
            @extend %position-center;
        }
    }
    .club {
        &-wrap {
            order: -1;
            flex-basis: 35%;
            @extend %mb-4;
            @extend %flex-c-c;
        }
        &-name {
            @extend %d-none;
        }
        &-img {
            width: 8rem;
            height: 8rem;
            @extend %p-2;
            @extend %white-bg;
            @extend %circle-radius;
        }
    }
    &.center-player {
        .player {
            &-image {
                width: 85%;
                top: 0;
                bottom: unset;
                transform: translate(-15%, 0);
                overflow: hidden;
                @extend %h-100;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-player-stats {
        @include commonCardWithMoreButton();
        &.waf-component {
            padding-left: 0;
        }
        .stats {
            &-swiper {
                padding-right: 0;
                margin-right: 0;
            }
            &-list {
                @include card-count(3, 3.2rem);
            }
        }
        .head-wrap {
            .title {
                margin: 0;
            }
            margin-bottom: var(--space-2);
        }
    }
}
@media screen and (max-width: $tablet-max-width) {
    .waf-player-stats {
        &.blue-btn-text {
            .head-tab {
                li a {
                    color: var(--secondary);
                }
            }
        }
        .team-card {
            aspect-ratio: 16/10;
            padding: var(--space-3);
        }
        .stats-data-label {
            max-width: 15ch;
        }
    }
}
@media screen and (max-width: $small-mobile-max-width) {
   .waf-player-stats .stats-data-list {
        width: 65%;
   } 
}